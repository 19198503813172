<template>
  <el-tabs value="在线工具">
    <el-tab-pane label="在线工具">
      <div class="box">
        <div>
          <div>私钥</div>
          <input type="text" v-model="keys.privateKey" />
        </div>
        <div>
          <div>公钥</div>
          <input type="text" v-model="keys.publicKey" />
        </div>
        <div>
          <div>字符串</div>
          <input type="text" v-model="res.str" />
        </div>
        <div>
          <div>加密后</div>
          <input type="text" v-model="res.jiami" />
        </div>
        <div>
          <div>解密后</div>
          <input type="text" v-model="res.jiemi" />
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="项目思路">
      <el-timeline>
        <el-timeline-item timestamp="安装" placement="top">
          npm install --save sm-crypto
        </el-timeline-item>
        <el-timeline-item timestamp="导入" placement="top">
          const sm2 = require("sm-crypto").sm2;
        </el-timeline-item>
        <el-timeline-item timestamp="使用" placement="top">
          <div>
            加密：sm2.doEncrypt(str, publicKey);// 需要加密的字符串,公钥
          </div>
          <div>
            解密：sm2.doDecrypt(str, privateKey);// 需要解密的字符串,私钥
          </div>
        </el-timeline-item>
      </el-timeline>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
const sm2 = require("sm-crypto").sm2;
export default {
  props: {},
  components: {},
  data() {
    return {
      keys: {
        // 私钥
        privateKey: "",
        // 公钥
        publicKey: "",
      },
      res: {
        str: "",
        jiami: "",
        jiemi: "",
      },
    };
  },
  watch: {
    "res.str"(str) {
      try {
        this.res.jiami = sm2.doEncrypt(str, this.keys.publicKey);
      } catch (error) {
        this.res.jiami = "加密失败";
      }
    },
    "res.jiami"(jiami) {
      try {
        this.res.jiemi = sm2.doDecrypt(jiami, this.keys.privateKey);
      } catch (error) {
        this.res.jiemi = "解密失败";
      }
    },
  },
  mounted() {
    const keys = sm2.generateKeyPairHex();
    Object.assign(this.keys, keys);
  },
};
</script>

<style lang="scss" scoped>
.box {
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    div {
      width: 3em;
      text-align: right;
      padding-right: 1em;
    }
    input {
      flex: 1;
      padding: 5px 10px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
    }
  }
}
</style>
