<template>
  <empty height="100vh">404页面</empty>
</template>

<script>
import empty from "@/components/empty";
export default {
  components: { empty },
};
</script>
