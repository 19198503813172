<template>
  <div>
    <el-button type="text" @click="open('/demo')">个人项目</el-button>
  </div>
</template>

<script>
export default {
  methods: {
    open(href) {
      window.open(href);
    },
  },
};
</script>
