<template>
  <el-tabs value="在线工具">
    <el-tab-pane label="在线工具">
      <div class="box">
        <div>
          <div>SM4秘钥</div>
          <input type="text" v-model="pwdKey" />
        </div>
        <template v-if="sm4Util">
          <div>
            <div>字符串</div>
            <input type="text" v-model="str" />
          </div>
          <div>
            <div>加密后</div>
            <input type="text" v-model="jiami" />
          </div>
          <div>
            <div>解密后</div>
            <input type="text" v-model="jiemi" />
          </div>
        </template>
        <template v-else>加密工具初始化失败：SM4秘钥必须为16位字符串</template>
      </div>
    </el-tab-pane>
    <el-tab-pane label="项目思路">
      <el-timeline>
        <el-timeline-item timestamp="安装" placement="top">
          npm install --save gm-crypt
        </el-timeline-item>
        <el-timeline-item timestamp="导入" placement="top">
          const SM4 = require("gm-crypt").sm4;
        </el-timeline-item>
        <el-timeline-item timestamp="使用" placement="top">
          <pre>
// 定义一个16位的字符串,一般由后端来定义
export const pwdKey = '1234567890abcdef';

// 加密
export function encrypt(pwd) {
  const sm4Config = {
    key: pwdKey,
    mode: 'ecb',
    cipherType: 'base64'
  }
  const sm4Util = new SM4(sm4Config)
  const ciphertext = sm4Util.encrypt(pwd)
  return ciphertext
}

// 解密
export function decrypt(pwd) {
  const sm4Config = {
    key: pwdKey,
    mode: 'ecb',
    cipherType: 'base64'
  }
  const sm4Util = new SM4(sm4Config)
  const plaintext = sm4Util.decrypt(pwd)
  return plaintext
}
          </pre>
        </el-timeline-item>
      </el-timeline>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
const SM4 = require("gm-crypt").sm4;
export default {
  data() {
    return {
      // SM4秘钥
      pwdKey: "",
      // sm4工具
      sm4Util: null,
      // 原字符串
      str: "",
      // 加密后
      jiami: "",
      // 解密后
      jiemi: "",
    };
  },
  watch: {
    str(str) {
      try {
        this.jiami = this.sm4Util.encrypt(str);
      } catch (error) {
        this.jiami = "加密失败";
      }
    },
    jiami(jiami) {
      try {
        this.jiemi = this.sm4Util.decrypt(jiami);
      } catch (error) {
        this.jiemi = "解密失败";
      }
    },
    pwdKey(pwdKey) {
      try {
        this.sm4Util = new SM4({
          key: pwdKey,
          mode: "ecb",
          cipherType: "base64",
        });
      } catch (error) {
        this.sm4Util = null;
      }
    },
  },
  mounted() {
    this.pwdKey = "1234567890abcdef";
  },
};
</script>

<style lang="scss" scoped>
.box {
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    div {
      width: 5em;
      text-align: right;
      padding-right: 1em;
    }
    input {
      flex: 1;
      padding: 5px 10px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
    }
  }
}
</style>
