<template>
  <div class="components-empty" :style="{ height }">
    <slot>暂无数据</slot>
  </div>
</template>
<script>
export default {
  props: ["height"],
};
</script>

<style lang="scss" scoped>
.components-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #888;
  user-select: none;
}
</style>
