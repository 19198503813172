<template>
  <el-tabs value="在线工具">
    <el-tab-pane label="在线工具">
      <div class="box">
        <div>
          <div>字符串</div>
          <input type="text" v-model="str" />
        </div>
        <div>
          <div>加密后</div>
          <input type="text" v-model="res" />
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="项目思路">
      <el-timeline>
        <el-timeline-item timestamp="安装" placement="top">
          npm install --save md5
        </el-timeline-item>
        <el-timeline-item timestamp="导入" placement="top">
          const md5 = require("md5");
        </el-timeline-item>
        <el-timeline-item timestamp="使用" placement="top">
          <div>加密：md5(str);// 需要加密的字符串</div>
          <div>解密：md5是单向函数，也就是说极其难以由散列函数输出的结果，回推输入的数据是什么</div>
        </el-timeline-item>
      </el-timeline>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
const md5 = require("md5");
export default {
  data() {
    return {
      str: "",
      res: "",
    };
  },
  watch: {
    str(str) {
      this.res = md5(str);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  height: 9999px;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    div {
      width: 3em;
      text-align: right;
      padding-right: 1em;
    }
    input {
      flex: 1;
      padding: 5px 10px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
    }
  }
}
</style>
